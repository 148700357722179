<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :data="data.congratulations" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <growers-statistics :data="growers" />
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col>
    </b-row>


  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import EcommerceMedal from './EcommerceMedal.vue'
import GrowersStatistics from './GrowersStatistics.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'

export default {
  components: {
    BRow,
    BCol,
    EcommerceMedal,
    GrowersStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
  },
  data() {
    return {
      data: {},
      growers: {},
    }
  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        console.log(userData)
        this.data.congratulations.name = userData.fullName || userData.username
      })

      this.growers =[
         
            {
              icon: 'UsersIcon',
              color: 'light-info',
              title: '889',
              subtitle: 'Growers',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'GlobeIcon',
              color: 'light-danger',
              title: '6',
              subtitle: 'Regions',
              customClass: 'mb-2 mb-sm-0',
            },
            {
              icon: 'CodesandboxIcon',
              color: 'light-success',
              title: '120',
              subtitle: 'Areas',
              customClass: '',
            },
  ];

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
